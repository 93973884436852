@font-face {
  font-family: Rubik;
  src: url('./fonts/Rubik-VariableFont_wght.ttf') format("truetype");
  /* font-weight: 400; */
}

@font-face {
  font-family: Rubik;
  src: url('./fonts/Rubik-Medium.ttf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url('./fonts/Rubik-Bold.ttf') format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Rubik;
  src: url('./fonts/Rubik-Black.ttf') format("truetype");
  font-weight: 900;
}

/* General */

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Rubik, Arial, sans-serif;
  color: #161616;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

p {
  font-size: 19px;
  line-height: 1.5em;
}

.container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
}

.centered {
  text-align: center;
}

.section {
  padding: 60px 0px;
}

.section.gradient {
  background: linear-gradient(180deg, #F8F83B -60.61%, rgba(255, 255, 255, 0) 100%);
}

.section.dark {
  background-color: #000;
  color: #fff;
}

.section-header {
  position: relative;
}

.raised {
  position: relative;
  z-index: 2;
}

.section-heading {
  font-size: 30px;
  line-height: 1.3em;
  margin-top: 0;
}

.section-subheading {
  font-size: f20px;
  line-height: 1.5em;
}

.button-row {
  margin-top: 50px;
}

.button, .MuiButton-containedPrimary, .minting-content .MuiButton-root, .MuiButton-contained.Mui-disabled {
  font-size: 18px !important;
  padding: 15px 50px !important;
  font-family: Rubik !important;
  font-weight: bold !important;
  color: #000 !important;
  font-weight: 600;
  cursor: pointer;
  background: #F8F83B !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  transition: all 300ms ease !important;
  border: 2px solid #F8F83B;
  text-decoration: none;
}

.nav .MuiButton-containedPrimary, .button.dark {
  background: #000 !important;
  color: #fff !important;
  text-transform: none !important;
  border-radius: 12px !important;
  padding: 12.5px 25px !important;
  font-size: 16px !important;
  border: 2px solid #000;
  /* margin-left: 20px; */
}

.button.dark.frame {
  background-color: transparent !important;
  color: #000 !important;
}

.MuiPaper-root .MuiButton-label {
  color: #fff !important;
}

.button:hover, .MuiButton-containedPrimary:hover {
  box-shadow: rgba(248, 248, 99, 0.30) 0px 8px 15px !important;
  transform: translateY(-2px);
}

.button-icon {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.MuiButton-contained.Mui-disabled {
  opacity: 0.70;
}

@media screen and (max-width: 680px) {
  .container {
    padding: 0 15px;
  }

  .section-heading {
    font-size: 26px;
  }
}

/* Header and Nav */
.header {
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-content {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.nav-branding, .nav {
  position: relative;
  z-index: 2;
}

.nav-branding {
  display: flex;
  align-items: center;
  font-size: 26px;
}

.nav-logo {
  height: 70px;
  pointer-events: none;
  border-radius: 100%;
  margin-right: 20px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.nav-link {
  color: #333;
  padding: 15px;
  text-decoration: none;
  border-bottom: solid 1px transparent;
}

.nav-link:hover {
  border-bottom: solid 1px #000;
}

.nav-link.button {
  font-weight: 400;
  margin-left: 15px;
  color: rgb(28,30,33);
}

.nav-buttons {
  /* padding-top: 8px; */
  margin-left: 20px;
}

.nav .MuiButton-containedPrimary:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 15px !important;
}

.nav-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
}

.nav-link {
  display: flex;
  align-items: center;
  font-weight: 700;
  transition: all 100ms ease;
}

.nav-link-icon {
  margin-right: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 680px) {
  .header {
    padding-top: 20px;
  }
  .nav {
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-box {
    flex-direction: column;
  }

  .nav-links {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-buttons {
    width: 100%;;
  }
}

/* Hero */
.hero.section {
  background: linear-gradient(180deg, #F8F83B -59.61%, rgba(255, 255, 255, 0) 100%);
  color: #161616;
  padding-top: 160px;
  padding-bottom: 90px;
}

.hero-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-copy-box {
  width: 60%;
  margin-left: 5%;
}

.hero-image-box {
  width: 32.5%;
  min-width: 338px;
}

.hero-image {
  border-radius: 16px;
  width: 100%;
  margin-bottom: 33px;
  position: relative;
  z-index: 2;
  max-height: 400px;
}

.hero-heading {
  font-size: 58px;
  line-height: 1.1em;
  
  margin-top: 10px;
  margin-bottom: 24px;
}

.hero-heading.max-width {
  max-width: 400px;
}

.hero-text {
  font-size: 20px;
  line-height: 1.6em;
  font-weight: 400;
}

@media screen and (max-width: 680px) {
  .hero.section {
    padding-top: 240px;
  }
  .hero-flex {
    flex-wrap: wrap;
  }

  .hero-image-box, .hero-copy-box {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }

  .hero-copy-box {
    margin-top: 33px;
    margin-left: 0;
  }

  .hero-image {
    width: auto;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  .hero-heading {
    font-size: 30px;
  }
}

/* Minting Section */
.minting-section {
  position: relative;
}

.minting-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minting-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minting-image {
  width: 122px;
  height: 122px; 
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  position: relative;
}

.minting-box {
  padding: 100px 0px 50px;
  background-color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  align-content: center;
  justify-content: center;
  border-radius: 14px;
  position: relative;
  bottom: 61px;
  background-image: url('./images/mint-bg-group.png');
  background-position-x: center;
  background-position-y: center;
}

.minting-unconnected {
  margin-bottom: 30px;;
}

.minting-available, .minting-amount {
  margin-top: 10px;
  font-size: 14px;
}

.minting-price {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 700;
}

.minting-quantity-box {
  margin: -15px 0 15px;
}

.minting-quantity-label {
  font-size: 14px;
}

.minting-quantity-input {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
 
}

.minting-quantity-button {
  background-color: #242424;
  height: 40px;
  width: 40px;
  border-radius: 1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 150ms ease;
}

.minting-quantity-button:hover {
  background-color: #333;
}



.minting-quantity-number {
  margin: 0 15px;
  min-width: 20px;
}

.minting-quantity-line {
  height: 2.5px;
  width: 15px;
  background-color: #F8F83B;
  
}

.minting-quantity-line.flipped {
  position: absolute;
  left: 12.5px;
  transform: rotateZ(90deg);
}

.minting-quantity-button.disabled .minting-quantity-line {
  opacity: 0.5;
}

.minting-progress-box {
  margin-top: 30px;
}

.minting-progress-bar {
  width: 100%;
  height: 10px;
  background-color: #2c2c2c;
}

.minting-progress {
  background-color: #3C9D00;
  height: 100%;
}

.minting-meta {
  margin-top: 12px;
}

@media screen and (max-width: 680px) {
  .minting-box {
    background-position-x: 25%;
    padding-bottom: 35px;
  }

  .minting-content {
    flex-flow: column-reverse;
  }

  .minting-available {
    margin-top: 10px;
  }
}

/* Rarities */

.rarities-box {

}

.rarities-category {
  /* margin-bottom: 30px; */
}

.rarities {
  display: flex;
  width: 100%;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.rarities.margined {
  margin-top: 50px;
}

.rarities-tabs {
  display: flex;
  margin-bottom: 27px;
  border-bottom: 1px solid #D5D5D5;
  padding: 0;
  overflow-y: visible;
}

.rarity-tab {
  position: relative;
  font-weight: 500;
  top: 1px;
  padding: 20px 0px;
  margin-right: 60px;
  cursor: pointer;
  color: #848484;
  transition: all 100ms ease;
  border-bottom: 1px solid #D5D5D5;
}

.rarity-tab:hover {
  color: #000;
}

.rarity-tab.current {
  border-bottom: 1px solid #000;
  color: #000;
}

.rarity-attribute-heading {
  color: #333;
  font-weight: 500;
}

.rarity {
  max-width: 19%;
  min-width: 180px;
  border-radius: 0px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.rarity-image {
  max-width: 100%;
  border-radius: 0px;
  box-shadow: 9px 11px 25px -10px rgba(0, 0, 0, 0.1);
}

.rarity-trait {
  margin-top: 14px;
  font-size: 14px;
}

.rarity-stat-meta {
  font-weight: 700;
}

@media screen and (max-width: 680px) {
  .rarities-tabs {
    overflow-x: scroll;
  }

  .rarity {
    max-width: auto;
    min-width: 150px;
  }
}

/* FAQ Items */

.faq-items {
  margin-top: 40px;
}

.faq-item {
  padding: 0 30px;
  margin-top: 32px;
  text-align: left;
  cursor: pointer;
  box-shadow: 1px 5px 30px 3px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  overflow-y: hidden;
}

.faq-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-right: 8px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13.5px;
  cursor: pointer;
}

.faq-question {
  margin-right: 50px;
  margin-bottom: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.33em;
  font-size: 22px;
  margin-top: 0px;
  font-weight: 500;

}

.faq-icon {
  position: relative;
  top: -2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-icon-line {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 150ms ease;
  position: relative;
}

.faq-icon-line.top {
  z-index: 2;
  position: absolute;
}

.faq-icon-line.flipped {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.faq-answer {
  font-size: 18px;
  line-height: 1.5em;
  padding-bottom: 10px;
  height: 0;
  color: #333;
  position: relative;
  top: 10px;
  transition: all 150ms ease;
}

.faq-answer.visible {
  height: auto;
}

@media screen and (max-width: 680px) {
  .faq-item {
    padding: 0 20px;
  }
  
  .faq-question {
    font-size: 18px;
  }

  .faq-icon {
    width: 14px;
    height: 14px;
  }

  .faq-answer p {
    font-size: 16px;
  }
}

/* Team */

.members {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.member {
  display: block;
  width: 31%;
  padding: 25px 40px 50px;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  transition: all 120ms ease;
  text-decoration: none;
  text-align: center;
  position: relative;
  z-index: 2;
  box-shadow: 9px 11px 25px -10px rgba(0, 0, 0, 0.1);
}

.member:nth-child(1) {
  background: linear-gradient(180deg, #DBFFDE 0%, #FEFFDB 100%);
}

.member:nth-child(2) {
  background: linear-gradient(180deg, #DBFFDE 0%, #DCE6FF 0.01%, #F3DBFF 100%);
}

.member:nth-child(3) {
  background: linear-gradient(180deg, #FFDBDB 0%, #FFDCDC 0.01%, #DBFFF6 100%);
}

.member:hover {
  transform: translateY(-10px);
}

.member-image {
  width: 95px;
  height: 95px;
  border-radius: 100%;
  
}

.member-name {
  font-weight: 700;
  margin-top: 15px;
  font-size: 18px;
}

.member-job {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.member-bio {
  margin-top: 16px;
  line-height: 1.4em;
  font-size: 14px;
}

@media screen and (max-width: 680px) {
  .members {
    flex-wrap: wrap;  
  } 
  
  .member {
    width: 100%;
    margin-top: 35px;
  }

  .member:nth-child(1) {
    margin-top: 0;
  }
}

/* Footer */

.footer {
  /* background: #000; */
  padding-bottom: 20px;
}

.footer-socials {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 63px 0 20px;
  width: 100%;
  border-top: 1px solid #D4D4D4;
  
}

.footer-social {
  padding: 15px 15px 13px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  text-decoration: none;
  background: #eee;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  transition: all 150ms ease;
}

.footer-social:hover {
  background: #F8F83B;
  
}

.footer-social svg {
  display: block;
}

.footer-nav-links {
  display: flex;
  justify-content: center;
}

.footer-nav-link {
  margin: 0 10px;
}

.footer-nav-link:hover {
  /* border: none; */
  /* color: #F8F83B; */
}

/* Whitepaper */

.whitepaper {
  margin: 70px auto 90px;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 850px;
  height: 1100px;
  background-color: #fff;
  border: 1px solid #ccc;
}

/* Roadmap */

.roadmap {
  margin: 70px auto 90px;
  width: 100%;
  height: 100%;
  max-width: 600px;
}

.roadmap img {
  width: 100%;
}

/* Marketplace */

.marketplace-box {
  margin-top: 80px;
}

.marketplace-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.marketplace-item {
  width: 25%;
  padding: 15px;
}

.marketplace-item-image {
  width: 100%;
}

.marketplace-attributes {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-top: none;
}

.marketplace-attribute {
  font-size: 13px;
  padding: 5px 0;
  line-height: 1.5em;
}

.marketplace-attribute-type {
  font-weight: 500;
  margin-right: 8px;
}

/* Connect Modal */

.MuiDialog-paper {
  padding: 20px 30px !important;
  width: 100% !important;
  max-width: 480px !important;
  background: #000 !important;
  border-radius: 23px !important;
}

.MuiDialogTitle-root {
  background: #000 !important;
  padding: 10px 0 0 !important;
  font-size: 20px !important;
}

.MuiDialogTitle-root .MuiTypography-root {
  font-weight: 700 !important;
}

.MuiList-root {
  background: #000 !important;
}

.MuiListItem-root {
  background: #1B1B1B !important;
  margin: 20px 0;
  box-shadow: none !important;
  border-radius: 15px;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.MuiListItem-root .MuiButtonBase-root {
  padding: 15px 30px !important;
}

.MuiDialogContent-root > .MuiList-root > .MuiListItem-root:last-child {
  background: #000 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 25px !important;
}

.MuiDialogContent-root .MuiButton-label {
  font-size: 18px !important;
}

.MuiDialogContent-root > .MuiList-root > .MuiListItem-root:last-child .MuiButton-label {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.MuiSvgIcon-root {
  color: #fff !important;
}

.MuiCollapse-wrapperInner {
  margin: -15px 0;
}

.MuiDialogTitle-root .MuiIconButton-root {
  position: relative;
  bottom: 20px;
  color: #fff !important;
}

@media screen and (max-width: 780px) {
  .MuiListItem-root {
    margin: 10px 0 !important;
  }

  .MuiListItem-root .MuiButtonBase-root {
    padding: 8px 20px !important;
  }

  .MuiDialogContent-root > .MuiList-root > .MuiListItem-root:last-child {
    margin-top: 10px !important;
  }
}

/* Arcade */

.arcade {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: -50px;
}

.arcade-item {
  width: 31%;
  margin: 0 1%;
  cursor: pointer;
  text-decoration: none;
  transition: all 150ms ease;
}

.arcade-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.04);
}

.arcade-item-image {
  width: 100%;
}

.panda-chooser {

}

.panda {
 cursor: pointer;
 transition: all 150ms ease;
}

.panda:hover .panda-chooser-image {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.06);
  transform: translateY(-8px)
}

.panda-chooser-image {
  border-radius: 5px;
  transition: all 150ms ease;
}

#phaser-target canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}